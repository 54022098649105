import React, { Component } from 'react';
import { Container, Card, Row, Col, Button, ButtonGroup, DropdownButton, Dropdown, Form, Spinner, Table, Modal, Badge, Tabs, Tab } from 'react-bootstrap';
import { t } from '../../helpers/translation_helper';
import NavBar from '../_partials/NavBar/_NavBar';
import Header from '../_partials/Header/_Header';
import { connect } from 'react-redux';
import { GET, POST } from '../../api';
import Pagination from '../_partials/Pagination';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faCheck, faClock, faEye, faEyeSlash, faFileExcel, faFileLines, faFolder, faGlobe, faImage, faList, faNewspaper, faPencil, faPlus, faSearch, faTrash, faTrashCan, faUser, faXmark } from '@fortawesome/free-solid-svg-icons';
import { renderTableBody, renderTableHead } from '../../helpers/table_helper';
import { toast } from 'react-toastify';
import { handleFormErrors } from '../../helpers/form_helpers';
import moment from 'moment/moment';
import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import SweetAlert from 'react-bootstrap-sweetalert';

var Chance = require('chance');
var chance = new Chance();
let cancelToken;
let cancelTokenDocuments;
let cancelTokenReflections;
let cancelTokenNotes;
const portalURL = process.env.REACT_APP_PORTAL_URL;

class CAS extends Component {

  docRef = React.createRef();

  defaultFormFields = {
    ib_cas_id: 0,
    ib_cas_name: '',
    ib_cas_description: '',
    ib_cas_startdate: '',
    ib_cas_enddate: '',
    ib_cas_iscasproject: 0,
    ib_cas_strands: [],
    ib_cas_approaches: [],
    ib_cas_service_actions: '',
    ib_cas_supervisors: [],
    ib_cas_target_outcome: [],
  };

  defaultReflectionFields = {
    ib_cas_ref_id: 0,
    ib_cas_ref_title: '',
    ib_cas_ref_desc: '',
    ib_cas_ref_date: new Date(),
    ib_cas_ref_target_outcome: [],
    ib_cas_id: 0,
    details: [],
  };

  defaultReflectionDetailFields = {
    ib_cas_refd_id: 0,
    ib_cas_refd_type: '',//URL', 'Image', 'File
    ib_cas_refd_value: '',
    ib_cas_refd_name: ''
  };

  state = {
    ...this.defaultFormFields,
    showFormModal: false,
    reflectionListModal: {
      show: false,
      data: null
    },
    supervisors: [],
    cdt: {
      fetching: false,
      search: '',
      rows: 10,
      offset: 0,
      order: 'cas.ib_cas_id',
      dir: 'DESC',
      lastPage: 0,
      page: 0,
      totalRecords: 0,
      rowsOptions: [10, 25, 50, 'All'],
      columns: [
        {
          id: 'cas.ib_cas_id',
          column: 'ib_cas_id',
          name: 'Actions',
          visibility: true,
          sortable: true,
        },
        {
          id: 'cas.ib_cas_iscasproject',
          column: 'ib_cas_iscasproject',
          name: 'CAS Project',
          visibility: true,
          sortable: true,
        },
        {
          id: 'cas.ib_cas_experience_status',
          column: 'ib_cas_experience_status',
          name: 'Experience Status',
          visibility: true,
          sortable: true,
        },
        {
          id: 'cas.ib_cas_name',
          column: 'ib_cas_name',
          name: 'Experience Name',
          visibility: true,
          sortable: true,
        },
        {
          id: 'cas.ib_cas_strands',
          column: 'ib_cas_strands',
          name: 'CAS',
          visibility: true,
          sortable: true,
        },
        {
          id: 'cas.ib_cas_progress',
          column: 'ib_cas_progress',
          name: 'Progress',
          visibility: true,
          sortable: true,
        },
      ],
      data: [],
    },
    refcdt: {
      fetching: false,
      search: '',
      rows: 10,
      offset: 0,
      order: 'casref.ib_cas_ref_id',
      dir: 'DESC',
      lastPage: 0,
      page: 0,
      totalRecords: 0,
      rowsOptions: [10, 25, 50, 'All'],
      columns: [
        // {
        //   id: 'casref.ib_cas_ref_id',
        //   column: 'ib_cas_ref_id',
        //   name: 'Actions',
        //   visibility: true,
        //   sortable: true,
        // },
        {
          id: 'casref.ib_cas_ref_title',
          column: 'ib_cas_ref_title',
          name: 'Reflection Title',
          visibility: true,
          sortable: true,
        },
      ],
      data: [],
    },
    docscdt: {
      fetching: false,
      search: '',
      rows: 10,
      offset: 0,
      order: 'casdocs.ib_cas_doc_id',
      dir: 'DESC',
      lastPage: 0,
      page: 0,
      totalRecords: 0,
      rowsOptions: [10, 25, 50, 'All'],
      columns: [
        {
          id: 'casdocs.ib_cas_doc_id',
          column: 'ib_cas_doc_id',
          name: 'Actions',
          visibility: true,
          sortable: true,
        },
        {
          id: 'casdocs.ib_cas_doc_caption',
          column: 'ib_cas_doc_caption',
          name: 'Document',
          visibility: true,
          sortable: true,
        },
        {
          id: 'casdocs.ib_cas_doc_date',
          column: 'ib_cas_doc_date',
          name: 'Date',
          visibility: true,
          sortable: true,
        },
        {
          id: 'casdocs.created_on',
          column: 'created_on',
          name: 'Created Date',
          visibility: true,
          sortable: true,
        },
      ],
      data: [],
    },
    notescdt: {
      fetching: false,
      search: '',
      rows: 10,
      offset: 0,
      order: 'casnotes.ib_cas_note_id',
      dir: 'DESC',
      lastPage: 0,
      page: 0,
      totalRecords: 0,
      rowsOptions: [10, 25, 50, 'All'],
      columns: [
        {
          id: 'casnotes.ib_cas_note_id',
          column: 'ib_cas_note_id',
          name: 'Actions',
          visibility: true,
          sortable: true,
        },
        {
          id: 'casnotes.ib_cas_note',
          column: 'ib_cas_note',
          name: 'Note',
          visibility: true,
          sortable: true,
        },
      ],
      data: [],
    },

    // Reflection modal and form
    addReflectionModal: {
      show: false,
      working: false,
      isEditing: false,
      toggle: (addReflectionForm = { ...this.defaultReflectionFields }, isEditing = false) => {
        this.setState({
          addReflectionModal: { ...this.state.addReflectionModal, show: !this.state.addReflectionModal.show, working: false, isEditing },
          addReflectionForm
        })
      },
    },
    addReflectionForm: { ...this.defaultReflectionFields },
    activeTab: 'documents',
    showDocumentModal: false,
    editDocumentModal: false,
    ib_cas_doc_id: 0,
    ib_cas_doc_caption: '',
    ib_cas_doc_name: '',
    ib_cas_doc_date: new Date(),
    CASDocWorking: false,
    ib_cas_note_id: 0,
    ib_cas_note: '',
    showNoteModal: false,
    editNoteModal: false,
    CASNoteWorking: false,
    deleteRefDetailConfirm: {
      show: false,
      id: 0,
    }
  }

  componentDidMount() {
    const { search, rows: limit, order, dir } = this.state.cdt;
    this.fetch({
      search,
      limit,
      order,
      dir,
      page: 0,
    });
    this.getSupervisors();
  }

  fetch = async (params) => {
    this.setState({
      cdt: {
        ...this.state.cdt,
        fetching: true,
      },
    });

    if (cancelToken) {
      cancelToken.abort();
    }
    cancelToken = new AbortController();

    try {
      const res = await GET('cas/get', {
        signal: cancelToken.signal,
        params: {
          search: params.search,
          order: params.order,
          dir: params.dir,
          limit: params.limit,
          offset: parseInt(params.page) * parseInt(params.limit),
        },
      });

      if (res.status === 200) {
        this.setState({
          cdt: {
            ...this.state.cdt,
            fetching: false,
            search: params.search,
            rows: parseInt(params.limit),
            offset: parseInt(params.page) * parseInt(params.limit),
            order: params.order,
            dir: params.dir,
            page: params.page,
            totalRecords: parseInt(res.data.total_count_filtered),
            lastPage: Math.ceil(
              parseInt(res.data.total_count_filtered) / parseInt(params.limit)
            ),
            data: res.data.data.map((item) => {
              return {
                uid: chance.guid(),
                ...item,
              };
            }),
          },
        });
      }
    } catch (err) {
      console.log('err', err);
      this.setState({
        cdt: {
          ...this.state.cdt,
          fetching: false,
        },
      });
    }
  };

  getSupervisors = async () => {
    if (this.getSupervisorsReq) {
      this.getSupervisorsReq.abort();
    }
    this.getSupervisorsReq = new AbortController();
    const res = await GET('cas/get-supervisors', {
      signal: this.getSupervisorsReq.signal,
      params: {
        class_id: this.props.selectedChild.class_id
      },
    });
    if (res) {
      this.setState({
        supervisors: res.data.map((v, i) => { return { ...v, label: v.full_name, value: v.user_id } }
        )
      })
    }

  }

  handleOpenFormModal = () => {
    this.setState({
      showFormModal: true,
    });
  };

  handleCloseFormModal = () => {
    this.setState({
      showFormModal: false,
      isEditing: false,
      ...this.defaultFormFields,
    });
  };

  handleCloseReflectionListModal = () => {
    this.setState({
      showReflectionModal: false,
    });
  };

  showHideColumn = (column_index) => {
    const columns = this.state.cdt.columns;
    columns[column_index].visibility = !columns[column_index].visibility;
    this.setState({ ctd: { ...this.state.ctd, columns } });
  };

  handleStrandClick = (strand) => {
    this.setState((prevState) => {
      const { ib_cas_strands } = prevState;
      if (ib_cas_strands.includes(strand)) {
        return { ib_cas_strands: ib_cas_strands.filter((item) => item !== strand) };
      } else {
        return { ib_cas_strands: [...ib_cas_strands, strand] };
      }
    });
  };

  exportExcel = async () => {
    const excelData = this.state.cdt.data.map((item) => {
      const row = {};
      this.state.cdt.columns
        .filter((column) => column.visibility)
        .map((column) => {
          if (column.name !== 'Actions') {
            row[column.name] = item[column.column];
          }
        });
      return row;
    });

    const fileName =
      'Communication Source Export ' + moment().format('DD-MM-YYYY');
    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const ws = XLSX.utils.json_to_sheet(excelData);

    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });

    FileSaver.saveAs(data, fileName + fileExtension);
  };

  saveUpdate = async () => {
    const { authData } = this.props;
    const { center_id } = authData.centerData;

    this.setState({ working: true });

    const {
      ib_cas_id,
      ib_cas_name,
      ib_cas_description,
      ib_cas_startdate,
      ib_cas_enddate,
      ib_cas_iscasproject,
      ib_cas_strands,
      ib_cas_approaches,
      ib_cas_service_actions,
      ib_cas_supervisors,
      ib_cas_target_outcome,
    } = this.state;

    const fData = new FormData();
    fData.append('center_id', center_id);
    fData.append(
      'casData',
      JSON.stringify({
        ib_cas_id,
        ib_cas_name,
        ib_cas_description,
        ib_cas_startdate: moment(ib_cas_startdate).format('YYYY-MM-DD'),
        ib_cas_enddate: moment(ib_cas_enddate).format('YYYY-MM-DD'),
        ib_cas_iscasproject,
        ib_cas_strands: JSON.stringify(ib_cas_strands),
        ib_cas_approaches: JSON.stringify(ib_cas_approaches),
        ib_cas_service_actions,
        ib_cas_supervisors: JSON.stringify(ib_cas_supervisors.map((v) => v.value)),
        ib_cas_target_outcome: JSON.stringify(ib_cas_target_outcome),
        created_by: this.props.selectedChild.child_user_id
      })
    );

    try {
      const res = await POST('cas/save', fData);
      if (res.data.type) {
        this.setState({ working: false });
        toast.success(t(res.data.message));
        this.fetch({
          search: '',
          limit: this.state.cdt.rows,
          order: this.state.cdt.order,
          dir: this.state.cdt.dir,
          page: 0,
        });
        this.handleCloseFormModal();
      } else {
        // toast.error(t(res.data.message));
        handleFormErrors(res.data.data);
        this.setState({ working: false });
      }
    } catch (err) {
      console.log('err', err);
      toast.error(t('Something went wrong!'));

      this.setState({ working: false });
    }
  };

  edit = (data) => {
    console.log('data', data);
    const supervisors = JSON.parse(data.ib_cas_supervisors).map((v, i) => {
      return {
        label: this.state.supervisors.find((supervisor) => supervisor.value == v).label,
        value: v
      }
    });
    this.setState({
      isEditing: true,
      ib_cas_id: data.ib_cas_id,
      ib_cas_name: data.ib_cas_name,
      ib_cas_description: data.ib_cas_description,
      ib_cas_startdate: new Date(data.ib_cas_startdate),
      ib_cas_enddate: new Date(data.ib_cas_enddate),
      ib_cas_iscasproject: data.ib_cas_iscasproject,
      ib_cas_strands: JSON.parse(data.ib_cas_strands),
      ib_cas_approaches: JSON.parse(data.ib_cas_approaches),
      ib_cas_service_actions: data.ib_cas_service_actions,
      ib_cas_supervisors: supervisors,
      ib_cas_target_outcome: JSON.parse(data.ib_cas_target_outcome),
    });
    this.handleOpenFormModal();
  };

  handleCheckbox = (event, stateKey) => {
    const { value, checked } = event.target;

    this.setState((prevState) => {
      let updatedState = [...prevState[stateKey]];
      if (checked) {
        if (!updatedState.includes(value)) {
          updatedState.push(value);
        }
      } else {
        updatedState = updatedState.filter((item) => item !== value);
      }
      return { [stateKey]: updatedState };
    });
  };

  handleReflectionOutcomeCheckbox = (event) => {
    const { value, checked } = event.target;

    this.setState((prevState) => {
      let updatedState = [...prevState['addReflectionForm']['ib_cas_ref_target_outcome']];
      if (checked) {
        if (!updatedState.includes(value)) {
          updatedState.push(value);
        }
      } else {
        updatedState = updatedState.filter((item) => item !== value);
      }
      return {
        addReflectionForm: {
          ...this.state.addReflectionForm,
          ib_cas_ref_target_outcome: updatedState
        }
      };
    });
  };

  CASView = (data) => {

    const supervisors = JSON.parse(data.ib_cas_supervisors).map((v, i) => {
      return {
        label: this.state.supervisors.find((supervisor) => supervisor.value == v).label,
        value: v
      }
    });

    this.setState({
      showReflectionModal: true,
      isEditing: true,
      ib_cas_id: data.ib_cas_id,
      ib_cas_name: data.ib_cas_name,
      ib_cas_description: data.ib_cas_description,
      ib_cas_startdate: new Date(data.ib_cas_startdate),
      ib_cas_enddate: new Date(data.ib_cas_enddate),
      ib_cas_iscasproject: data.ib_cas_iscasproject,
      ib_cas_strands: JSON.parse(data.ib_cas_strands),
      ib_cas_approaches: JSON.parse(data.ib_cas_approaches),
      ib_cas_service_actions: data.ib_cas_service_actions,
      ib_cas_supervisors: supervisors,
      ib_cas_target_outcome: JSON.parse(data.ib_cas_target_outcome),
      ib_cas_supervisor_review: data.ib_cas_supervisor_review,
    }, () => {
      this.getReflections({
        search: '',
        limit: this.state.refcdt.rows,
        order: this.state.refcdt.order,
        dir: this.state.refcdt.dir,
        page: 0,
      });
      this.getDocs({
        search: '',
        limit: this.state.docscdt.rows,
        order: this.state.docscdt.order,
        dir: this.state.docscdt.dir,
        page: 0,
      });
      this.getNotes({
        search: '',
        limit: this.state.notescdt.rows,
        order: this.state.notescdt.order,
        dir: this.state.notescdt.dir,
        page: 0,
      });
    });
  }

  getReflections = async (params) => {
    this.setState({
      refcdt: {
        ...this.state.refcdt,
        fetching: true,
      },
    });

    if (cancelTokenReflections) {
      cancelTokenReflections.abort();
    }
    cancelTokenReflections = new AbortController();

    try {
      const res = await GET('cas/get-reflections', {
        signal: cancelTokenReflections.signal,
        params: {
          search: params.search,
          order: params.order,
          dir: params.dir,
          limit: params.limit,
          offset: parseInt(params.page) * parseInt(params.limit),
          ib_cas_id: this.state.ib_cas_id
        },
      });

      console.log('res', res);

      if (res.status === 200) {
        this.setState({
          refcdt: {
            ...this.state.refcdt,
            fetching: false,
            search: params.search,
            rows: parseInt(params.limit),
            offset: parseInt(params.page) * parseInt(params.limit),
            order: params.order,
            dir: params.dir,
            page: params.page,
            totalRecords: parseInt(res.data.total_count_filtered),
            lastPage: Math.ceil(
              parseInt(res.data.total_count_filtered) / parseInt(params.limit)
            ),
            data: res.data.data.map((item) => {
              return {
                uid: chance.guid(),
                ...item,
              };
            }),
          },
        });
      }
    } catch (err) {
      console.log('err', err);
      this.setState({
        refcdt: {
          ...this.state.refcdt,
          fetching: false,
        },
      });
    }
  };

  editReflection = (data) => {
    console.log('editReflection data', data);
    let ib_cas_ref_target_outcome = [];
    if (data.ib_cas_ref_target_outcome) {
      ib_cas_ref_target_outcome = JSON.parse(data.ib_cas_ref_target_outcome);
    }
    this.state.addReflectionModal.toggle({
      ...data,
      ib_cas_ref_target_outcome,
      details: []
    }, true)
  }

  setReflectionValue = (column, value) => {
    let { addReflectionForm } = this.state
    addReflectionForm[column] = value
    this.setState({ addReflectionForm })
  }

  addReflectionDetail = (ib_cas_refd_type) => {
    let { addReflectionForm } = this.state
    addReflectionForm['details'].push({ ...this.defaultReflectionDetailFields, ib_cas_refd_type })
    this.setState({ addReflectionForm })
  }

  delRefDetail = async () => {
    const { id } = this.state.deleteRefDetailConfirm;

    try {
      const res = await GET('cas/delete-reflection-detail', {
        params: {
          ib_cas_refd_id: id,
        },
      });
      this.getReflections({
        search: this.state.refcdt.search,
        limit: this.state.refcdt.rows,
        order: this.state.refcdt.order,
        dir: this.state.refcdt.dir,
        page: this.state.refcdt.page,
      });
      this.setState({ deleteRefDetailConfirm: { show: false, id: 0 } });
      toast.success(t(res.data.message));
    } catch (err) {
      console.log(err);
      toast.error(t('Something went wrong!'));
    }
  }

  setReflectionDetailValue = (index, column, value) => {
    let { addReflectionForm } = this.state
    addReflectionForm['details'][index][column] = value
    this.setState({ addReflectionForm })
  };

  saveUpdateReflection = async () => {
    const { addReflectionForm, addReflectionModal } = this.state;
    this.setState({ addReflectionModal: { ...addReflectionModal, working: true } });

    const fData = new FormData();
    addReflectionForm.details = addReflectionForm.details.map((v, i) => {
      if ((v.ib_cas_refd_type == 'File' || v.ib_cas_refd_type == 'Image') && v.ib_cas_refd_value?.name) {
        fData.append('reflectionFiles[' + i + ']', v.ib_cas_refd_value);
      }
      return { ...v }
    })
    fData.append('addReflectionForm', JSON.stringify(addReflectionForm));
    fData.append('child_user_id', this.props.selectedChild.child_user_id);

    try {
      const res = await POST('cas/save-reflection', fData);
      if (res.data.type) {
        addReflectionModal.toggle()
        toast.success(t(res.data.message));
        this.getReflections({
          search: '',
          limit: this.state.refcdt.rows,
          order: this.state.refcdt.order,
          dir: this.state.refcdt.dir,
          page: 0,
        });
      } else {
        // toast.error(t(res.data.message));
        handleFormErrors(res.data.data);
        this.setState({ working: false });
      }
    } catch (err) {
      console.log('err', err);
      toast.error(t('Something went wrong!'));

      this.setState({ working: false });
    }
  }

  handleCloseDocumentModal = () => {
    this.setState({
      showDocumentModal: false,
      editDocumentModal: false,
      ib_cas_doc_id: 0,
      ib_cas_doc_caption: '',
      ib_cas_doc_name: '',
      ib_cas_doc_date: new Date(),
    });
  }

  saveUpdateCASDoc = async () => {
    const { ib_cas_id, ib_cas_doc_id, ib_cas_doc_caption, ib_cas_doc_name, ib_cas_doc_date, editDocumentModal } = this.state;

    if (!ib_cas_doc_caption) {
      toast.error(t('Please enter document caption'));
      return;
    }

    if (!editDocumentModal && !ib_cas_doc_name) {
      toast.error(t('Please select a file'));
      return;
    }

    this.setState({ CASDocWorking: true });

    const fData = new FormData();
    fData.append('ib_cas_doc_id', ib_cas_doc_id);
    fData.append('ib_cas_doc_caption', ib_cas_doc_caption);
    fData.append('ib_cas_doc_name', ib_cas_doc_name);
    fData.append('ib_cas_doc_date', moment(ib_cas_doc_date).format('YYYY-MM-DD'));
    fData.append('child_user_id', this.props.selectedChild.child_user_id);
    fData.append('ib_cas_id', ib_cas_id);

    try {
      const res = await POST('cas/save-cas-doc', fData);
      if (res.data.type) {
        this.setState({ CASDocWorking: false });
        toast.success(t(res.data.message));
        this.getDocs({
          search: '',
          limit: this.state.docscdt.rows,
          order: this.state.docscdt.order,
          dir: this.state.docscdt.dir,
          page: 0,
        });
        this.handleCloseDocumentModal();
      } else {
        // toast.error(t(res.data.message));
        handleFormErrors(res.data.data);
        this.setState({ CASDocWorking: false });
      }
    } catch (err) {
      console.log('err', err);
      toast.error(t('Something went wrong!'));

      this.setState({ CASDocWorking: false });
    }
  }

  getDocs = async (params) => {
    this.setState({
      docscdt: {
        ...this.state.docscdt,
        fetching: true,
      },
    });

    if (cancelTokenDocuments) {
      cancelTokenDocuments.abort();
    }
    cancelTokenDocuments = new AbortController();

    try {
      const res = await GET('cas/get-documents', {
        signal: cancelTokenDocuments.signal,
        params: {
          search: params.search,
          order: params.order,
          dir: params.dir,
          limit: params.limit,
          offset: parseInt(params.page) * parseInt(params.limit),
          ib_cas_id: this.state.ib_cas_id
        },
      });

      if (res.status === 200) {
        this.setState({
          docscdt: {
            ...this.state.docscdt,
            fetching: false,
            search: params.search,
            rows: parseInt(params.limit),
            offset: parseInt(params.page) * parseInt(params.limit),
            order: params.order,
            dir: params.dir,
            page: params.page,
            totalRecords: parseInt(res.data.total_count_filtered),
            lastPage: Math.ceil(
              parseInt(res.data.total_count_filtered) / parseInt(params.limit)
            ),
            data: res.data.data.map((item) => {
              return {
                uid: chance.guid(),
                ...item,
              };
            }),
          },
        });
      }
    } catch (err) {
      console.log('err', err);
      this.setState({
        docscdt: {
          ...this.state.docscdt,
          fetching: false,
        },
      });
    }
  };

  editDoc = (data) => {
    this.setState({
      showDocumentModal: true,
      editDocumentModal: true,
      ib_cas_doc_caption: data.ib_cas_doc_caption,
      ib_cas_doc_date: new Date(data.ib_cas_doc_date),
      ib_cas_doc_id: data.ib_cas_doc_id,
    });
  };

  deleteDoc = async (data) => {
    try {
      const res = await GET('cas/delete-doc', {
        params: {
          ib_cas_doc_id: data.ib_cas_doc_id,
        },
      });
      this.setState({
        docscdt: {
          ...this.state.docscdt,
          data: this.state.docscdt.data.filter((item) => item.uid !== data.uid),
        },
      });
      toast.success(t(res.data.message));
    } catch (err) {
      console.log(err);
      toast.error(t('Something went wrong!'));
    }
  }

  getNotes = async (params) => {
    this.setState({
      notescdt: {
        ...this.state.notescdt,
        fetching: true,
      },
    });

    if (cancelTokenNotes) {
      cancelTokenNotes.abort();
    }
    cancelTokenNotes = new AbortController();

    try {
      const res = await GET('cas/get-notes', {
        signal: cancelTokenNotes.signal,
        params: {
          search: params.search,
          order: params.order,
          dir: params.dir,
          limit: params.limit,
          offset: parseInt(params.page) * parseInt(params.limit),
          ib_cas_id: this.state.ib_cas_id
        },
      });

      if (res.status === 200) {
        this.setState({
          notescdt: {
            ...this.state.notescdt,
            fetching: false,
            search: params.search,
            rows: parseInt(params.limit),
            offset: parseInt(params.page) * parseInt(params.limit),
            order: params.order,
            dir: params.dir,
            page: params.page,
            totalRecords: parseInt(res.data.total_count_filtered),
            lastPage: Math.ceil(
              parseInt(res.data.total_count_filtered) / parseInt(params.limit)
            ),
            data: res.data.data.map((item) => {
              return {
                uid: chance.guid(),
                ...item,
              };
            }),
          },
        });
      }
    } catch (err) {
      console.log('err', err);
      this.setState({
        notescdt: {
          ...this.state.notescdt,
          fetching: false,
        },
      });
    }
  };

  handleCloseNoteModal = () => {
    this.setState({
      showNoteModal: false,
      editNoteModal: false,
      ib_cas_note_id: 0,
      ib_cas_note: ''
    });
  }

  saveUpdateCASNote = async () => {
    const { ib_cas_id, ib_cas_note_id, ib_cas_note } = this.state;

    if (!ib_cas_note) {
      toast.error(t('Please enter note'));
      return;
    }


    this.setState({ CASNoteWorking: true });

    const fData = new FormData();
    fData.append('ib_cas_note_id', ib_cas_note_id);
    fData.append('ib_cas_note', ib_cas_note);
    fData.append('child_user_id', this.props.selectedChild.child_user_id);
    fData.append('ib_cas_id', ib_cas_id);

    try {
      const res = await POST('cas/save-cas-note', fData);
      if (res.data.type) {
        this.setState({ CASNoteWorking: false });
        toast.success(t(res.data.message));
        this.getNotes({
          search: '',
          limit: this.state.notescdt.rows,
          order: this.state.notescdt.order,
          dir: this.state.notescdt.dir,
          page: 0,
        });
        this.handleCloseNoteModal();
      } else {
        // toast.error(t(res.data.message));
        handleFormErrors(res.data.data);
        this.setState({ CASNoteWorking: false });
      }
    } catch (err) {
      console.log('err', err);
      toast.error(t('Something went wrong!'));

      this.setState({ CASNoteWorking: false });
    }
  }

  editNote = (data) => {
    this.setState({
      showNoteModal: true,
      editNoteModal: true,
      ib_cas_note: data.ib_cas_note,
      ib_cas_note_id: data.ib_cas_note_id,
    });
  };

  deleteNote = async (data) => {
    try {
      const res = await GET('cas/delete-note', {
        params: {
          ib_cas_note_id: data.ib_cas_note_id,
        },
      });
      this.setState({
        notescdt: {
          ...this.state.notescdt,
          data: this.state.notescdt.data.filter((item) => item.uid !== data.uid),
        },
      });
      toast.success(t(res.data.message));
    } catch (err) {
      console.log(err);
      toast.error(t('Something went wrong!'));
    }
  }

  renderCell = (column_data, column_id, column_index, dataObj) => {
    const { authData } = this.props;
    const { center_id } = authData.centerData;
    const { addReflectionModal } = this.state;

    let tdData;

    // Action Buttons
    if (column_id == 'ib_cas_id') {
      tdData = (
        <>
          {dataObj.visitby_type != 2 && (
            <>
              {/* <Button
                onClick={() => this.delete(dataObj)}
                variant='danger'
                size='sm'
                className='me-1'>
                <FontAwesomeIcon icon={faTrash} />
              </Button> */}
              <Button
                onClick={() => this.edit(dataObj)}
                variant='warning'
                size='sm'
                className='me-1'>
                <FontAwesomeIcon icon={faPencil} />
              </Button>
              <Button
                title='CAS'
                onClick={() => this.CASView(dataObj)}
                variant='success'
                size='sm'
                className='me-1'>
                <FontAwesomeIcon icon={faFileLines} />
              </Button>
            </>
          )}
        </>
      );
    } else if (column_id == 'ib_cas_strands') {
      tdData = JSON.parse(column_data).map((strand, index) => {
        let className = '';
        if (strand === 'Creativity') {
          className = 'badge badge-creativity me-1';
        } else if (strand === 'Activity') {
          className = 'badge badge-activity me-1';
        } else if (strand === 'Service') {
          className = 'badge badge-service me-1';
        }
        return (<span key={index} className={className}>
          {strand}
        </span>)
      });
    } else if (column_id == 'ib_cas_iscasproject') {
      tdData = <Badge bg={column_data == 1 ? 'success' : 'danger'}>{column_data == 1 ? t('YES') : t('NO')}</Badge>
    } else {
      tdData = column_data;
    }

    return <td key={column_index}>{tdData}</td>;
  };

  render() {

    console.log('%cSTATE', 'color: orange; font-size: 20px; font-weight: bold;', this.state);
    let { addReflectionModal, addReflectionForm } = this.state

    return (
      <Container fluid>
        <div id='iedu-layout'>
          <NavBar />
          <div id='page-content'>
            <Header lite={true} heading={t('CAS')} backBtn />
            <div className='grey-section'>
              <Card
                className='border-0'
                style={{ borderRadius: '20px' }}>
                <Card.Header
                  style={{
                    backgroundColor: '#fff',
                    borderTopLeftRadius: '20px',
                    borderTopRightRadius: '20px',
                  }}>
                  <Row>
                    <Col md={6} className='d-flex align-items-center'>
                      <ButtonGroup size='sm' className='me-3 float-left'>
                        {/* <Button><FontAwesomeIcon icon={faFilePdf} /> {t('PDF')}</Button> */}
                        <Button onClick={() => this.exportExcel()}>
                          <FontAwesomeIcon icon={faFileExcel} /> {t('Excel')}
                        </Button>
                        {/* <Button><FontAwesomeIcon icon={faPrint} /> {t('Print')}</Button> */}
                        <DropdownButton
                          autoClose={'outside'}
                          size='sm'
                          as={ButtonGroup}
                          title={t('Column Visibility')}>
                          {this.state.cdt.columns.map((column, column_index) => (
                            <Dropdown.Item
                              key={column_index}
                              className={
                                column.visibility
                                  ? 'column-name-item'
                                  : 'column-name-item active'
                              }
                              onClick={() => this.showHideColumn(column_index)}>
                              <FontAwesomeIcon
                                icon={column.visibility ? faEye : faEyeSlash}
                              />{' '}
                              {column.name}
                            </Dropdown.Item>
                          ))}
                        </DropdownButton>
                      </ButtonGroup>
                      <div style={{ display: 'inline-block' }}>
                        {t('Show')}
                        <Form.Select
                          value={this.state.cdt.rows}
                          size='sm'
                          onChange={(e) =>
                            this.fetch({
                              search: this.state.cdt.search,
                              limit: e.target.value,
                              order: this.state.cdt.order,
                              dir: this.state.cdt.dir,
                              page: this.state.cdt.page,
                            })
                          }
                          style={{
                            width: 70,
                            marginInline: 5,
                            display: 'inline-block',
                          }}>
                          {this.state.cdt.rowsOptions.map((row, row_index) => (
                            <option key={row_index}>{row}</option>
                          ))}
                        </Form.Select>
                        {t('Records')}
                      </div>
                    </Col>
                    <Col md={6} className='d-flex justify-content-end'>
                      <div className='cdt-search'>
                        <input
                          type='text'
                          ref={this.searchFieldRef}
                          defaultValue={this.state.cdt.search}
                          onChange={(e) =>
                            this.fetch({
                              search: e.target.value,
                              limit: this.state.cdt.rows,
                              order: this.state.cdt.order,
                              dir: this.state.cdt.dir,
                              page: 0,
                            })
                          }
                          placeholder={t('Search...')}
                        />
                        <button
                          onClick={() => {
                            this.fetch({
                              search: '',
                              limit: this.state.cdt.rows,
                              order: this.state.cdt.order,
                              dir: this.state.cdt.dir,
                              page: 0,
                            });
                            this.searchFieldRef.current.value = '';
                          }}>
                          <FontAwesomeIcon
                            icon={this.state.cdt.search == '' ? faSearch : faXmark}
                            color='#000'
                          />
                        </button>
                      </div>
                      <button
                        onClick={this.handleOpenFormModal}
                        className='btn btn-success btn-sm ms-3'>
                        <FontAwesomeIcon
                          icon={faPlus}
                          color='#fff'
                          style={{ fontSize: 12 }}
                        />
                      </button>
                    </Col>
                  </Row>
                </Card.Header>
                <Card.Body style={{ paddingTop: 8 }}>
                  <Row>
                    {this.state.cdt.fetching && (
                      <Col
                        md={12}
                        className='py-5 d-flex flex-column align-items-center'>
                        <Spinner as='span' animation='grow' size='sm' />{' '}
                        {t('Loading Data')}
                      </Col>
                    )}
                    {!this.state.cdt.fetching && this.state.cdt.data.length === 0 && (
                      <Col
                        md={12}
                        className='py-5 d-flex flex-column align-items-center'>
                        {t('No Data Found')}
                      </Col>
                    )}
                    {!this.state.cdt.fetching && this.state.cdt.data.length !== 0 && (
                      <Col md={12} className='pt-2'>
                        <div
                          id='datatable-list'
                          className='mb-3'
                          style={{ width: 'initial' }}>
                          <Table responsive>
                            {renderTableHead(this)}
                            {renderTableBody(this)}
                          </Table>
                        </div>
                      </Col>
                    )}
                  </Row>
                  <Pagination
                    pageCount={this.state.cdt.lastPage || 1}
                    forcePage={this.state.cdt.page}
                    callbackParams={{
                      search: this.state.cdt.search,
                      limit: this.state.cdt.rows,
                      order: this.state.cdt.order,
                      dir: this.state.cdt.dir,
                    }}
                    callback={this.fetch}
                  />
                </Card.Body>
              </Card>
            </div>
          </div>
        </div>

        {/* FORM MODAL */}
        <Modal
          backdrop='static'
          keyboard={false}
          size={'xxl'}
          show={this.state.showFormModal}
          onHide={this.handleCloseFormModal}>
          <Modal.Header closeButton>
            <Modal.Title>
              {this.state.isEditing ? t('Edit') : t('Add')} {t('CAS')}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col md={6}>
                <Row>
                  <Col md={8}>
                    <Form.Group className='mb-3'>
                      <Form.Label>{t('Experience Name')} <span className='text-danger'>*</span></Form.Label>
                      <Form.Control
                        type='text'
                        defaultValue={this.state.ib_cas_name}
                        onChange={(e) =>
                          this.setState({ ib_cas_name: e.target.value })
                        }
                      />
                    </Form.Group>
                  </Col>
                  <Col md={4} style={{ paddingTop: 38 }}>
                    <Form.Check
                      type="switch"
                      label={t('CAS Project')}
                      id="ib_cas_iscasproject"
                      checked={this.state.ib_cas_iscasproject == 1}
                      onChange={(e) => this.setState({ ib_cas_iscasproject: this.state.ib_cas_iscasproject == 0 ? 1 : 0 })}
                    />
                  </Col>
                  <Col md={12} className='mb-3'>
                    <Form.Label>{t('Approaches')}</Form.Label>
                    <Row>
                      <Col md={5} className="mb-1">
                        <Form.Check
                          type="checkbox"
                          label={t('Ongoing')}
                          value="Ongoing"
                          onChange={(e) => this.handleCheckbox(e, 'ib_cas_approaches')}
                          checked={this.state.ib_cas_approaches.includes('Ongoing')}
                        />
                      </Col>
                      <Col md={6} className="mb-1">
                        <Form.Check
                          type="checkbox"
                          label={t('School-based')}
                          value="School-based"
                          onChange={(e) => this.handleCheckbox(e, 'ib_cas_approaches')}
                          checked={this.state.ib_cas_approaches.includes('School-based')}
                        />
                      </Col>
                      <Col md={5} className="mb-1">
                        <Form.Check
                          type="checkbox"
                          label={t('Community-based')}
                          value="Community-based"
                          onChange={(e) => this.handleCheckbox(e, 'ib_cas_approaches')}
                          checked={this.state.ib_cas_approaches.includes('Community-based')}
                        />
                      </Col>
                      <Col md={6} className="mb-1">
                        <Form.Check
                          type="checkbox"
                          label={t('Individual')}
                          value="Individual"
                          onChange={(e) => this.handleCheckbox(e, 'ib_cas_approaches')}
                          checked={this.state.ib_cas_approaches.includes('Individual')}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col md={6} className='mb-3'>
                    <Form.Label>{t('Start Date')}</Form.Label>
                    <DatePicker
                      selected={this.state.ib_cas_startdate}
                      onChange={(date) =>
                        this.setState({ ib_cas_startdate: date })
                      }
                      selectsStart
                      dateFormat={'dd-MM-yyyy'}
                    />
                  </Col>
                  <Col md={6} className='mb-3'>
                    <Form.Label>{t('End Date')}</Form.Label>
                    <DatePicker
                      selected={this.state.ib_cas_enddate}
                      onChange={(date) =>
                        this.setState({ ib_cas_enddate: date })
                      }
                      selectsStart
                      dateFormat={'dd-MM-yyyy'}
                    />
                  </Col>
                  <Col md={12}>
                    <Form.Label>{t('Description & Goals')}</Form.Label>
                    <Form.Control defaultValue={this.state.ib_cas_description} onChange={(e) => this.setState({ ib_cas_description: e.target.value })}
                      as="textarea" rows={3} />
                  </Col>
                </Row>
              </Col>
              <Col md={6}>
                <Col md={12} className='mb-3'>
                  <Form.Label>{t('Strands')}</Form.Label>
                  <div>
                    <button
                      className={`btn-strand btn-creativity ${this.state.ib_cas_strands.includes('Creativity') ? 'active' : ''}`}
                      onClick={() => this.handleStrandClick('Creativity')}
                    >
                      <span>C</span>{t('Creativity')}
                    </button>
                    <button
                      className={`btn-strand btn-activity ${this.state.ib_cas_strands.includes('Activity') ? 'active' : ''}`}
                      onClick={() => this.handleStrandClick('Activity')}
                    >
                      <span>A</span>{t('Activity')}
                    </button>
                    <button
                      className={`btn-strand btn-service ${this.state.ib_cas_strands.includes('Service') ? 'active' : ''}`}
                      onClick={() => this.handleStrandClick('Service')}
                    >
                      <span>S</span>{t('Service')}
                    </button>
                  </div>
                </Col>
                <Col md={12} className='mb-3'>
                  <Form.Label>{t('Type of Service Action')}</Form.Label>
                  <Row>
                    <Col md={5} className='mb-1'>
                      <Form.Check
                        disabled={!this.state.ib_cas_strands.includes('Service')}
                        type={'radio'}
                        label={t('Direct')}
                        name='ib_cas_service_actions'
                        value={'Direct'}
                        onChange={(e) => this.setState({ ib_cas_service_actions: e.target.value })}
                        checked={this.state.ib_cas_service_actions == 'Direct'}
                      />
                    </Col>
                    <Col md={6} className='mb-1'>
                      <Form.Check
                        disabled={!this.state.ib_cas_strands.includes('Service')}
                        type={'radio'}
                        label={t('Indirect')}
                        name='ib_cas_service_actions'
                        value={'Indirect'}
                        onChange={(e) => this.setState({ ib_cas_service_actions: e.target.value })}
                        checked={this.state.ib_cas_service_actions == 'Indirect'}
                      />
                    </Col>
                    <Col md={5} className='mb-1'>
                      <Form.Check
                        disabled={!this.state.ib_cas_strands.includes('Service')}
                        type={'radio'}
                        label={t('Advocacy')}
                        name='ib_cas_service_actions'
                        value={'Advocacy'}
                        onChange={(e) => this.setState({ ib_cas_service_actions: e.target.value })}
                        checked={this.state.ib_cas_service_actions == 'Advocacy'}
                      />
                    </Col>
                    <Col md={6} className='mb-1'>
                      <Form.Check
                        disabled={!this.state.ib_cas_strands.includes('Service')}
                        type={'radio'}
                        label={t('Research')}
                        name='ib_cas_service_actions'
                        value={'Research'}
                        onChange={(e) => this.setState({ ib_cas_service_actions: e.target.value })}
                        checked={this.state.ib_cas_service_actions == 'Research'}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col md={12}>
                  <Form.Group className='mb-3'>
                    <Form.Label>{t('Supervisors')}</Form.Label>
                    <Select
                      classNamePrefix='custom-styled-select'
                      isMulti
                      closeMenuOnSelect={false}
                      options={this.state.supervisors}
                      onChange={(event) => this.setState({ ib_cas_supervisors: event })}
                      value={this.state.ib_cas_supervisors}
                      menuPortalTarget={document.body}
                      styles={{ menuPortal: (base) => ({ ...base, zIndex: 99999 }) }}
                    />
                  </Form.Group>
                </Col>
                <Col md={12}>
                  <Form.Label>{t('Please select your targeted learning outcomes:')}</Form.Label>
                  <Row>
                    <Col md={5} className='mb-1'>
                      <Form.Check
                        type={'checkbox'}
                        label={t('Strength & Growth')}
                        value={'Strength & Growth'}
                        onChange={(e) => this.handleCheckbox(e, 'ib_cas_target_outcome')}
                        checked={this.state.ib_cas_target_outcome.includes('Strength & Growth')}
                      />
                    </Col>
                    <Col md={6} className='mb-1'>
                      <Form.Check
                        type={'checkbox'}
                        label={t('Challenge & Skills')}
                        value={'Challenge & Skills'}
                        onChange={(e) => this.handleCheckbox(e, 'ib_cas_target_outcome')}
                        checked={this.state.ib_cas_target_outcome.includes('Challenge & Skills')}
                      />
                    </Col>
                    <Col md={5} className='mb-1'>
                      <Form.Check
                        type={'checkbox'}
                        label={t('Initiative & Planning')}
                        value={'Initiative & Planning'}
                        onChange={(e) => this.handleCheckbox(e, 'ib_cas_target_outcome')}
                        checked={this.state.ib_cas_target_outcome.includes('Initiative & Planning')}
                      />
                    </Col>
                    <Col md={6} className='mb-1'>
                      <Form.Check
                        type={'checkbox'}
                        label={t('Commitment & Perseverance')}
                        value={'Commitment & Perseverance'}
                        onChange={(e) => this.handleCheckbox(e, 'ib_cas_target_outcome')}
                        checked={this.state.ib_cas_target_outcome.includes('Commitment & Perseverance')}
                      />
                    </Col>
                    <Col md={5} className='mb-1'>
                      <Form.Check
                        type={'checkbox'}
                        label={t('Collaborative Skills')}
                        value={'Collaborative Skills'}
                        onChange={(e) => this.handleCheckbox(e, 'ib_cas_target_outcome')}
                        checked={this.state.ib_cas_target_outcome.includes('Collaborative Skills')}
                      />
                    </Col>
                    <Col md={6} className='mb-1'>
                      <Form.Check
                        type={'checkbox'}
                        label={t('Global Engagement')}
                        value={'Global Engagement'}
                        onChange={(e) => this.handleCheckbox(e, 'ib_cas_target_outcome')}
                        checked={this.state.ib_cas_target_outcome.includes('Global Engagement')}
                      />
                    </Col>
                    <Col md={5} className='mb-1'>
                      <Form.Check
                        type={'checkbox'}
                        label={t('Ethics of Choices & Actions')}
                        value={'Ethics of Choices & Actions'}
                        onChange={(e) => this.handleCheckbox(e, 'ib_cas_target_outcome')}
                        checked={this.state.ib_cas_target_outcome.includes('Ethics of Choices & Actions')}
                      />
                    </Col>
                  </Row>
                </Col>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' onClick={this.handleCloseFormModal}>
              {t('Close')}
            </Button>
            <Button
              disabled={this.state.working}
              variant='success'
              onClick={() => this.saveUpdate()}>
              {t('Save')}{' '}
              {this.state.working ? (
                <Spinner
                  as='span'
                  animation='grow'
                  size='sm'
                  role='status'
                  aria-hidden='true'
                />
              ) : (
                ''
              )}
            </Button>
          </Modal.Footer>
        </Modal>

        {/* CAS VIEW MODAL */}
        <Modal
          backdrop='static'
          keyboard={false}
          fullscreen
          show={this.state.showReflectionModal}
          onHide={this.handleCloseReflectionListModal}>
          <Modal.Header style={{ backgroundColor: '#15bbe7' }} closeButton>
            <Modal.Title style={{ color: '#fff' }}>
              {t('CAS') + ' - ' + this.props.selectedChild.child_name}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col md={6}>
                <Row>
                  <Col md={8} className='mb-3'>
                    <strong>{t('Experience Name')}</strong><br />
                    {this.state.ib_cas_name}
                  </Col>
                  <Col md={4} className='mb-3'>
                    {this.state.ib_cas_iscasproject == 1 &&
                      <Badge bg='success'><FontAwesomeIcon icon={faCheck} /> {t('CAS Project')}</Badge>
                    }
                  </Col>
                  <Col md={12} className='mb-3'>
                    <strong>{t('Approaches')}</strong><br />
                    {this.state.ib_cas_approaches.join(', ')}
                  </Col>
                  <Col md={6} className='mb-3'>
                    <strong>{t('Start Date')}</strong><br />
                    {moment(this.state.ib_cas_startdate).format('DD-MM-YYYY')}
                  </Col>
                  <Col md={6} className='mb-3'>
                    <strong>{t('End Date')}</strong><br />
                    {moment(this.state.ib_cas_enddate).format('DD-MM-YYYY')}
                  </Col>
                  <Col md={12}>
                    <strong>{t('Description & Goals')}</strong><br />
                    {this.state.ib_cas_description}
                  </Col>
                </Row>
              </Col>
              <Col md={6}>
                <Col md={12} className='mb-3'>
                  <strong>{t('CAS')}</strong><br />
                  {this.state.ib_cas_strands.map((strand, index) => {
                    let className = '';
                    if (strand === 'Creativity') {
                      className = 'badge badge-creativity me-1';
                    } else if (strand === 'Activity') {
                      className = 'badge badge-activity me-1';
                    } else if (strand === 'Service') {
                      className = 'badge badge-service me-1';
                    }
                    return (<span key={index} className={className}>
                      {strand}
                    </span>)
                  })}

                </Col>
                <Col md={12} className='mb-3'>
                  <strong>{t('Type of Service Action')}</strong><br />
                  {this.state.ib_cas_service_actions}
                </Col>
                <Col md={12}>
                  <Form.Group className='mb-3'>
                    <strong>{t('Supervisors')}</strong><br />
                    {this.state.ib_cas_supervisors.map(item => item.label).join(', ')}
                  </Form.Group>
                </Col>
                <Col md={12}>
                  <strong>{t('Targeted Learning Outcomes')}</strong><br />
                  {this.state.ib_cas_target_outcome.join(', ')}
                </Col>
              </Col>
            </Row>
            <div className='p-3 mt-3' style={{ backgroundColor: '#f9f9f9', borderRadius: 10 }}>
              <div style={{ position: 'absolute', right: 30 }}>
                {this.state.activeTab == 'documents' &&
                  <Button
                    onClick={() => this.setState({ showDocumentModal: true })}
                    variant='success'
                    size='sm'
                    className='me-1'>
                    <FontAwesomeIcon icon={faPlus} /> {t('Add Document')}
                  </Button>
                }
                {this.state.activeTab == 'reflections' &&
                  <Button
                    onClick={() => addReflectionModal.toggle({ ...this.defaultReflectionFields, ib_cas_id: this.state.ib_cas_id, details: [] }, false)}
                    variant='success'
                    size='sm'
                    className='me-1'>
                    <FontAwesomeIcon icon={faPlus} /> {t('Add Reflection')}
                  </Button>
                }
                {this.state.activeTab == 'notes' &&
                  <Button
                    onClick={() => this.setState({ showNoteModal: true })}
                    variant='success'
                    size='sm'
                    className='me-1'>
                    <FontAwesomeIcon icon={faPlus} /> {t('Add Note')}
                  </Button>
                }
              </div>
              <Tabs
                defaultActiveKey={this.state.activeTab}
                id="cas-tabs"
                className="mb-3 cas-tabs"
                onSelect={(activeTab) => this.setState({ activeTab })}>
                {/* TAB DOCUMENTS */}
                <Tab className='cas-tab-content' eventKey="documents" title={t('Documents')}>
                  <Row>
                    {this.state.docscdt.fetching && (
                      <Col
                        md={12}
                        className='py-5 d-flex flex-column align-items-center'>
                        <Spinner as='span' animation='grow' size='sm' />{' '}
                        {t('Loading Data')}
                      </Col>
                    )}
                    {!this.state.docscdt.fetching && this.state.docscdt.data.length === 0 && (
                      <Col
                        md={12}
                        className='py-5 d-flex flex-column align-items-center'>
                        {t('No Data Found')}
                      </Col>
                    )}
                    {!this.state.docscdt.fetching && this.state.docscdt.data.length !== 0 && (
                      <Col md={12} className='pt-2'>
                        <div
                          id='datatable-list'
                          className='mb-3'
                          style={{ width: 'initial' }}>
                          <Table responsive>
                            <tbody>
                              {this.state.docscdt.data.map((item, index) => (
                                <tr key={index}>
                                  <td style={{ width: 85 }}>
                                    <Button variant='warning' className='me-1'
                                      onClick={() => this.editDoc(item)}
                                      size='sm'>
                                      <FontAwesomeIcon icon={faPencil} />
                                    </Button>
                                    <Button variant='danger'
                                      onClick={() => this.deleteDoc(item)}
                                      size='sm'>
                                      <FontAwesomeIcon icon={faTrash} />
                                    </Button>
                                  </td>
                                  <td style={{ width: 100 }}>
                                    <Badge bg="dark" style={{ marginTop: 5 }}>
                                      <FontAwesomeIcon className='me-1' icon={faCalendar} /> {moment(item.ib_cas_doc_date).format('DD-MM-YYYY')}
                                    </Badge></td>
                                  <td style={{ verticalAlign: 'middle' }}>
                                    <Button variant='link' className='p-0' onClick={() => {
                                      let url = portalURL + this.props.authData.centerData.center_uuid + '/academic_ib_cas_documents/' + item.ib_cas_doc_name;
                                      window.open(url, '_blank');
                                    }}>
                                      {item.ib_cas_doc_caption}
                                    </Button>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        </div>
                      </Col>
                    )}
                  </Row>
                  <Pagination
                    pageCount={this.state.docscdt.lastPage || 1}
                    forcePage={this.state.docscdt.page}
                    callbackParams={{
                      search: this.state.docscdt.search,
                      limit: this.state.docscdt.rows,
                      order: this.state.docscdt.order,
                      dir: this.state.docscdt.dir,
                    }}
                    callback={this.getDocs}
                  />
                </Tab>
                {/* TAB REFLECTIONS */}
                <Tab eventKey="reflections" title={t('Reflections')}>
                  <Row>
                    {this.state.refcdt.fetching && (
                      <Col
                        md={12}
                        className='py-5 d-flex flex-column align-items-center'>
                        <Spinner as='span' animation='grow' size='sm' />{' '}
                        {t('Loading Data')}
                      </Col>
                    )}
                    {!this.state.refcdt.fetching && this.state.refcdt.data.length === 0 && (
                      <Col
                        md={12}
                        className='py-5 d-flex flex-column align-items-center'>
                        {t('No Data Found')}
                      </Col>
                    )}
                    {!this.state.refcdt.fetching && this.state.refcdt.data.length !== 0 && (
                      <Col md={12} className='pt-2'>
                        <div
                          id='datatable-list'
                          className='mb-3'
                          style={{ width: 'initial' }}>
                          <Table responsive>
                            <tbody>
                              {this.state.refcdt.data.map((item, index) => (
                                <tr key={index}>
                                  <td>
                                    <div className='p-1'>
                                      <Row>
                                        <Col md={6} className='mb-2'>
                                          <Button variant='warning' className='me-2'
                                            onClick={() => this.editReflection(item)}
                                            size='sm'>
                                            <FontAwesomeIcon icon={faPencil} />
                                          </Button>
                                          {item.ib_cas_ref_date &&
                                            <Badge bg="dark" style={{ marginRight: 10 }}>
                                              <FontAwesomeIcon className='me-1' icon={faCalendar} /> {moment(item.ib_cas_ref_date).format('DD-MM-YYYY')}
                                            </Badge>
                                          }
                                          {item.ib_cas_ref_title}
                                        </Col>
                                        <Col md={6} className='text-end mb-2'>
                                          {item.ib_cas_ref_approved == 1 &&
                                            <Badge bg='success' className='me-2'><FontAwesomeIcon icon={faCheck} /> {t('Approved')}</Badge>
                                          }
                                          <Badge bg="info" className='me-2'>
                                            <FontAwesomeIcon className='me-1' icon={faUser} /> {item.created_by_name}
                                          </Badge>
                                        </Col>
                                        <Col md={12} className='mb-3'>
                                          <div dangerouslySetInnerHTML={{ __html: item.ib_cas_ref_desc }} />
                                          {item.ib_cas_ref_target_outcome && JSON.parse(item.ib_cas_ref_target_outcome).map((item, index) => <Badge key={index} bg='warning' className='me-2'>{item}</Badge>)}
                                        </Col>
                                        <Col md={12}>
                                          {item.reflection_details.map((item, index) => {
                                            let icon = null;
                                            let url = null;

                                            if (item.ib_cas_refd_type == 'URL') {
                                              icon = faGlobe;
                                              url = item.ib_cas_refd_value;
                                            } else if (item.ib_cas_refd_type == 'Image') {
                                              icon = faImage;
                                              url = portalURL + this.props.authData.centerData.center_uuid + '/academic_ib_cas_reflection_details/' + item.ib_cas_refd_value;
                                            } else if (item.ib_cas_refd_type == 'File') {
                                              icon = faFolder;
                                              url = portalURL + this.props.authData.centerData.center_uuid + '/academic_ib_cas_reflection_details/' + item.ib_cas_refd_value;
                                            }

                                            const name = item.ib_cas_refd_name ? item.ib_cas_refd_name : item.ib_cas_refd_type;

                                            return (<div style={{ display: 'inline-block', position: 'relative' }} key={index}>
                                              <Button variant='danger' size="sm" style={{ width: 20, height: 20, borderRadius: 10, padding: 0, lineHeight: '10px', position: 'absolute', top: -7, right: 5 }} onClick={() => this.setState({ deleteRefDetailConfirm: { show: true, id: item.ib_cas_refd_id } })}>
                                                <FontAwesomeIcon icon={faTrashCan} style={{ fontSize: 10 }} />
                                              </Button>
                                              <Button
                                                onClick={() => {
                                                  window.open(url, '_blank');
                                                }}
                                                key={index}
                                                variant='outline-dark'
                                                size='sm'
                                                className='me-3'>
                                                <FontAwesomeIcon icon={icon} /> {name}
                                              </Button>
                                            </div>)
                                          })}
                                        </Col>
                                      </Row>
                                    </div>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        </div>
                      </Col>
                    )}
                  </Row>
                  <Pagination
                    pageCount={this.state.refcdt.lastPage || 1}
                    forcePage={this.state.refcdt.page}
                    callbackParams={{
                      search: this.state.refcdt.search,
                      limit: this.state.refcdt.rows,
                      order: this.state.refcdt.order,
                      dir: this.state.refcdt.dir,
                    }}
                    callback={this.getReflections}
                  />
                </Tab>
                {/* TAB NOTES */}
                <Tab eventKey="notes" title={t('Notes')}>
                  <Row>
                    {this.state.notescdt.fetching && (
                      <Col
                        md={12}
                        className='py-5 d-flex flex-column align-items-center'>
                        <Spinner as='span' animation='grow' size='sm' />{' '}
                        {t('Loading Data')}
                      </Col>
                    )}
                    {!this.state.notescdt.fetching && this.state.notescdt.data.length === 0 && (
                      <Col
                        md={12}
                        className='py-5 d-flex flex-column align-items-center'>
                        {t('No Data Found')}
                      </Col>
                    )}
                    {!this.state.notescdt.fetching && this.state.notescdt.data.length !== 0 && (
                      <Col md={12} className='pt-2'>
                        <div
                          id='datatable-list'
                          className='mb-3'
                          style={{ width: 'initial' }}>
                          <Table responsive>
                            <tbody>
                              {this.state.notescdt.data.map((item, index) => (
                                <tr key={index}>
                                  <td style={{ width: 85 }}>
                                    <Button variant='warning' className='me-1'
                                      onClick={() => this.editNote(item)}
                                      size='sm'>
                                      <FontAwesomeIcon icon={faPencil} />
                                    </Button>
                                    <Button variant='danger'
                                      onClick={() => this.deleteNote(item)}
                                      size='sm'>
                                      <FontAwesomeIcon icon={faTrash} />
                                    </Button>
                                  </td>
                                  <td style={{ width: 100 }}>
                                    <Badge bg="dark">
                                      <FontAwesomeIcon className='me-1' icon={faCalendar} /> {moment(item.created_on).format('DD-MM-YYYY')}
                                    </Badge>
                                  </td>
                                  <td style={{ verticalAlign: 'middle' }}>
                                    {item.ib_cas_note}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        </div>
                      </Col>
                    )}
                  </Row>
                  <Pagination
                    pageCount={this.state.notescdt.lastPage || 1}
                    forcePage={this.state.notescdt.page}
                    callbackParams={{
                      search: this.state.notescdt.search,
                      limit: this.state.notescdt.rows,
                      order: this.state.notescdt.order,
                      dir: this.state.notescdt.dir,
                    }}
                    callback={this.getNotes}
                  />
                </Tab>
                {/* TAB SUPERVISOR REVIEWS */}
                <Tab eventKey="supervisor-review" title={t('Supervisor Review')}>
                  {!this.state.ib_cas_supervisor_review && <div className='text-center'>{t('No Review Found')}</div>}
                  {this.state.ib_cas_supervisor_review && this.state.ib_cas_supervisor_review != '' &&
                    <div dangerouslySetInnerHTML={{ __html: this.state.ib_cas_supervisor_review }} />
                  }
                </Tab>
              </Tabs>
            </div>
          </Modal.Body>
        </Modal>

        {/* REFLECTION MODAL */}
        <Modal backdropClassName='second-backdrop' backdrop='static' keyboard={false} size={'lg'} show={addReflectionModal.show} onHide={addReflectionModal.toggle}>
          <Modal.Header closeButton>
            <Modal.Title>
              {addReflectionModal.isEditing ? t('Edit') : t('Add')} {t('Reflection')}
            </Modal.Title>
          </Modal.Header>
          {addReflectionModal.show &&
            <Modal.Body>
              <Row>
                <Col md={8}>
                  <Form.Group className='mb-3'>
                    <Form.Label>{t('Title')} <span className='text-danger'>*</span></Form.Label>
                    <Form.Control
                      type='text'
                      defaultValue={addReflectionForm.ib_cas_ref_title}
                      onChange={(e) =>
                        this.setReflectionValue('ib_cas_ref_title', e.target.value)
                      }
                    />
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group className='mb-3'>
                    <Form.Label>{t('Date')}</Form.Label>
                    <DatePicker
                      selected={addReflectionForm.ib_cas_ref_date}
                      onChange={(date) =>
                        this.setReflectionValue('ib_cas_ref_date', date)
                      }
                      selectsStart
                      dateFormat={'dd-MM-yyyy'}
                    />
                  </Form.Group>
                </Col>
                <Col md={12} className='ck-editor-ht-200'>
                  <Form.Group className='mb-3'>
                    <Row>
                      <Col> <Form.Label>{t('Notes')}</Form.Label></Col>
                    </Row>
                    <CKEditor
                      config={{
                        removePlugins: ['Title'],
                        toolbar: {
                          items: [
                            'heading',
                            '|',
                            'bold',
                            'italic',
                            'alignment',
                            '|',
                            'numberedList',
                            'bulletedList',
                            '|',
                            'indent',
                            'outdent',
                            '|',
                            'undo',
                            'redo'
                          ]
                        }
                      }}
                      editor={Editor}
                      data={addReflectionForm.ib_cas_ref_desc}
                      onChange={(
                        event,
                        editor
                      ) => {
                        const ib_cas_ref_desc = editor.getData();
                        this.setReflectionValue('ib_cas_ref_desc', ib_cas_ref_desc);
                      }}
                    />
                  </Form.Group>
                </Col>
                <Col md={12} className='mb-3'>
                  <Form.Label>{t('Please select your targeted learning outcomes:')}</Form.Label>
                  <Row>
                    {this.state.ib_cas_target_outcome.map((item, index) => (
                      <Col key={index} md={5} className='mb-1'>
                        <Form.Check
                          type={'checkbox'}
                          label={t(item)}
                          value={item}
                          onChange={this.handleReflectionOutcomeCheckbox}
                          checked={addReflectionForm.ib_cas_ref_target_outcome?.includes(item)}
                        />
                      </Col>
                    ))}
                  </Row>
                </Col>
              </Row>
              {/* {!addReflectionModal.isEditing && */}
              <Row>
                <Col className='d-flex flex-row'>
                  <Button variant='info' size='sm' className='me-2' onClick={() => this.addReflectionDetail('URL')}>Add URL</Button>
                  <Button variant='info' size='sm' className='me-2' onClick={() => this.addReflectionDetail('Image')}>Add Image</Button>
                  <Button variant='info' size='sm' className='me-2' onClick={() => this.addReflectionDetail('File')}>Add File</Button>
                </Col>
              </Row>
              {/* } */}
              <Row>
                {addReflectionForm.details.map((v, i) => {
                  return <Col key={i} md="6">
                    <div className='border p-1 m-1'>
                      <Form.Group className='mb-3'>
                        <div className='badge badge-light-danger me-1'>{t('Reflection')}{' ' + (i + 1) + ':'}</div>
                        <Form.Label>
                          {t('Title')}
                        </Form.Label>
                        <Form.Control
                          type='text'
                          defaultValue={v.ib_cas_refd_name}
                          onChange={(e) =>
                            this.setReflectionDetailValue(i, 'ib_cas_refd_name', e.target.value)
                          }
                        />
                      </Form.Group>
                      {(v.ib_cas_refd_type == 'URL') &&
                        <Form.Group className='mb-3'>
                          <Form.Label>{t('URL')}</Form.Label>
                          <Form.Control
                            type='text'
                            defaultValue={v.ib_cas_refd_value}
                            onChange={(e) =>
                              this.setReflectionDetailValue(i, 'ib_cas_refd_value', e.target.value)
                            }
                          />
                        </Form.Group>
                      }
                      {(v.ib_cas_refd_type == 'Image' || v.ib_cas_refd_type == 'File') &&
                        <Form.Group controlId='formFile'>
                          <Form.Label>
                            {v.ib_cas_refd_type + ' ' + t('Attachment')}
                          </Form.Label>
                          <Form.Control
                            type='file'
                            onChange={(e) => this.setReflectionDetailValue(i, 'ib_cas_refd_value', e.target.files[0])}
                          />
                        </Form.Group>
                      }
                      {(v.ib_cas_refd_type == 'Image' && v.ib_cas_refd_value && (typeof v.ib_cas_refd_value == 'string')) && (
                        <div className='pic-prev d-flex justify-content-center'>
                          <img
                            alt=''
                            style={{ maxWidth: 270, height: '100%', }}
                            src={portalURL + this.props.authData.centerData.center_uuid + '/academic_ib_cas_reflection_details/' + v.ib_cas_refd_value}
                          />
                        </div>
                      )}
                      {(v.ib_cas_refd_type == 'File' && v.ib_cas_refd_value && (typeof v.ib_cas_refd_value == 'string')) && (
                        <div className='pic-prev d-flex justify-content-center'>
                          <a href={portalURL + this.props.authData.centerData.center_uuid + '/academic_ib_cas_reflection_details/' + v.ib_cas_refd_value} target='_blank'>Open File</a>
                        </div>
                      )}
                    </div>
                  </Col>
                })}
              </Row>
            </Modal.Body>
          }
          <Modal.Footer>
            <Button variant='secondary' onClick={addReflectionModal.toggle}>
              {t('Close')}
            </Button>
            <Button
              disabled={addReflectionModal.working}
              variant={addReflectionModal.isEditing ? 'warning' : 'success'}
              onClick={() => this.saveUpdateReflection()}>
              {addReflectionModal.isEditing ? t('Update') : t('Save')}{' '}
              {addReflectionModal.working && (
                <Spinner
                  as='span'
                  animation='grow'
                  size='sm'
                  role='status'
                  aria-hidden='true'
                />
              )}
            </Button>
          </Modal.Footer>
        </Modal>

        {/* DOCUMENT MODAL */}
        <Modal backdropClassName='second-backdrop' backdrop='static' keyboard={false} show={this.state.showDocumentModal} onHide={this.handleCloseDocumentModal}>
          <Modal.Header closeButton>
            <Modal.Title>
              {this.state.editDocumentModal ? t('Edit') : t('Add')} {t('Document')}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group controlId='formFile'>
              <Row>
                <Col>
                  <Form.Control
                    className='mb-3'
                    placeholder={t('Caption')}
                    type='text'
                    value={this.state.ib_cas_doc_caption}
                    onChange={(e) => this.setState({ ib_cas_doc_caption: e.target.value })}
                  />
                </Col>
                <Col>
                  <DatePicker
                    selected={this.state.ib_cas_doc_date}
                    onChange={(date) =>
                      this.setState({ ib_cas_doc_date: date })
                    }
                    selectsStart
                    dateFormat={'dd-MM-yyyy'}
                  />
                </Col>
              </Row>
              {!this.state.editDocumentModal &&
                <>
                  <Form.Control
                    ref={this.docRef}
                    type='file'
                    onChange={(e) => {
                      const selectedFile = e.target.files[0];
                      if (selectedFile.size > 10 * 1024 * 1024) {
                        toast.error(t('Allowed file size is 10MB!'));
                        this.docRef.current.value = '';
                        return;
                      }
                      this.setState({ ib_cas_doc_name: selectedFile })
                    }}
                  />
                  <div className='text-end'>
                    <small className='text-danger'>{t('Allowed file size:')} <strong>10MB</strong></small>
                  </div>
                </>
              }
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' onClick={this.handleCloseDocumentModal}>
              {t('Close')}
            </Button>
            <Button
              disabled={this.state.CASDocWorking}
              variant={'success'}
              onClick={() => this.saveUpdateCASDoc()}>
              {t('Save')}{' '}
              {this.state.CASDocWorking && (
                <Spinner
                  as='span'
                  animation='grow'
                  size='sm'
                  role='status'
                  aria-hidden='true'
                />
              )}
            </Button>
          </Modal.Footer>
        </Modal>

        {/* NOTE MODAL */}
        <Modal backdropClassName='second-backdrop' backdrop='static' keyboard={false} show={this.state.showNoteModal} onHide={this.handleCloseNoteModal}>
          <Modal.Header closeButton>
            <Modal.Title>
              {this.state.editNoteModal ? t('Edit') : t('Add')} {t('Note')}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group controlId='formFile'>
              <Form.Control
                className='mb-3'
                placeholder={t('Note')}
                as="textarea" rows={3}
                value={this.state.ib_cas_note}
                onChange={(e) => this.setState({ ib_cas_note: e.target.value })}
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' onClick={this.handleCloseNoteModal}>
              {t('Close')}
            </Button>
            <Button
              disabled={this.state.CASNoteWorking}
              variant={'success'}
              onClick={() => this.saveUpdateCASNote()}>
              {t('Save')}{' '}
              {this.state.CASNoteWorking && (
                <Spinner
                  as='span'
                  animation='grow'
                  size='sm'
                  role='status'
                  aria-hidden='true'
                />
              )}
            </Button>
          </Modal.Footer>
        </Modal>

        {/* CONFIRM DELETE REFLECTION DELETE */}
        <SweetAlert show={this.state.deleteRefDetailConfirm.show} warning showCancel confirmBtnText={t('Yes, delete it!')} confirmBtnBsStyle='danger' title={t('Are you sure?')} onConfirm={this.delRefDetail} onCancel={() => this.setState({ deleteRefDetailConfirm: { show: false, id: 0 } })} focusCancelBtn>
          {t('Confirm Delete!')}
        </SweetAlert>

      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  authData: state.auth.authData,
  defaultLanguage: state.language.defaultLanguage,
  selectedChild: state.selectedChild.data
});

export default connect(mapStateToProps, null)(CAS);
