import React, { Component } from 'react';
import { Container, Card, Row, Col, Image, Spinner, ButtonGroup, Button, Offcanvas, Form, Badge, Breadcrumb, Modal, Tabs, Tab } from 'react-bootstrap';
import { t } from '../../helpers/translation_helper';
import NavBar from '../_partials/NavBar/_NavBar';
import Header from '../_partials/Header/_Header';
import { connect } from 'react-redux';
import './classview.css';
import moment from 'moment';
import axios from 'axios';
import { toast } from 'react-toastify';
import SweetAlert from 'react-bootstrap-sweetalert';
import TimelineCard from './TimelineCard';
import { DateRangePicker } from 'react-date-range';
import { faChevronUp, faChevronDown, faSearch, } from '@fortawesome/free-solid-svg-icons';
import { White, Gray, } from '../../helpers/brand_colors_helper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Select from 'react-select';
import { Chance } from 'chance';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
const chance = new Chance();
const apiURL = process.env.REACT_APP_API_URL;



const portalURL = process.env.REACT_APP_PORTAL_URL;

const defaultTimelineTypes = [
  {
    uid: chance.unique(chance.integer, 1, { min: 99999999999, max: 999999999999 })[0],
    name: "Assignment",
    ut_type: "Assignment",
    picture: require('../../assets/images/user-timeline/assignment.png'),
    active: true,
    show: true,
    classmain_types: [0, 1, 2, 3, 4],
  },
  {
    uid: chance.unique(chance.integer, 1, { min: 99999999999, max: 999999999999 })[0],
    name: "Exam",
    ut_type: "Exam",
    picture: require('../../assets/images/user-timeline/exam.png'),
    active: true,
    show: false,
    classmain_types: [0, 1, 2, 3, 4],
  },
  {
    uid: chance.unique(chance.integer, 1, { min: 99999999999, max: 999999999999 })[0],
    name: "Activity",
    ut_type: "Academic Activity",
    picture: require('../../assets/images/user-timeline/activity.png'),
    active: true,
    show: true,
    classmain_types: [0, 1, 2, 3, 4],
  },
  {
    uid: chance.unique(chance.integer, 1, { min: 99999999999, max: 999999999999 })[0],
    name: "Online Homework",
    ut_type: "Homework",
    picture: require('../../assets/images/user-timeline/newsletter.png'),
    active: true,
    show: true,
    classmain_types: [0, 1, 2, 3, 4],
  },
  {
    uid: chance.unique(chance.integer, 1, { min: 99999999999, max: 999999999999 })[0],
    name: "Agenda",
    ut_type: "Agenda",
    picture: require('../../assets/images/user-timeline/agenda.png'),
    active: true,
    show: true,
    classmain_types: [0, 1, 2, 3, 4],
  },
];



class ClassView extends Component {

  state = {
    working: false,
    utWorking: false,
    startDate: '',
    endDate: '',
    getUserTimelineData: {
      refreshing: false,
      finished: false,
      limit: 12,
      offset: 0,
      data: []
    },
    offset: 0,
    finishedData: false,
    loadMore: false,
    filter: {
      show: false,
      toggleFilter: (show) => this.setState({ filter: { ...this.state.filter, show: (this.state.filter.show == show) ? false : show } }),
      setData: (key, value) => {
        this.setState({ filter: { ...this.state.filter, [key]: value } })
      },
      selectedUT: defaultTimelineTypes.map((v, i) => { return { label: v.name, value: v.ut_type } }),
      selectedStudent: null,
      selectedDateRange: { startDate: new Date(), endDate: new Date(), key: 'selection' },
      keyword: '',
      unpublished: false,
      allStudents: [],
    },
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.getUserTimeline();
    // this.getAcademicActivities();
    // this.getLessonAttributes();
    // this.getAssessmentMenu();
  }

  getUserTimeline = () => {

    const { authData, selectedChild } = this.props;
    const { class_id, child_id } = selectedChild;

    if (this.state.getUserTimelineData.refreshing) {
      return false
    }
    if (this.state.getUserTimelineData.finished) {
      return false
    }

    this.setState({ getUserTimelineData: { ...this.state.getUserTimelineData, refreshing: true } }, () => {

      if (this.getUserTimelineRequest) {
        this.getUserTimelineRequest.abort()
      }
      this.getUserTimelineRequest = new AbortController();

      let userTimelineDataLen = this.state.getUserTimelineData.data.length

      let fData = new FormData()
      fData.append('selectedTimelineTypes', JSON.stringify(this.state.filter.selectedUT.map((v, i) => { return { ut_type: v.value, active: true } })))
      fData.append('offset', (userTimelineDataLen > 0) ? this.state.getUserTimelineData.offset : 0)
      fData.append('limit', this.state.getUserTimelineData.limit)
      fData.append('user_id', this.props.authData.loginData.user_id)
      fData.append('child_id', child_id)
      fData.append('class_id', class_id)
      fData.append('center_id', this.props.authData.centerData.center_id)
      fData.append('center_timezone', this.props.authData.centerData.center_timezone)
      fData.append('ut_id', (userTimelineDataLen > 0) ? this.state.getUserTimelineData.data[userTimelineDataLen - 1].ut_id : 0)
      fData.append('activeFilterDateFrom', (this.state.filter.selectedDateRange.startDate) ? moment(this.state.filter.selectedDateRange.startDate).format('YYYY-MM-DD') + ' 00:00:00' : null)
      fData.append('activeFilterDateTo', (this.state.filter.selectedDateRange.endDate) ? moment(this.state.filter.selectedDateRange.endDate).format('YYYY-MM-DD') + ' 23:59:59' : null)


      axios.post(apiURL + 'user-timeline', fData, {
        params: {
          auth_key: this.props.authData.loginData.auth_key,
        },
        signal: this.getUserTimelineRequest.signal,
      })
        .then((res) => {
          // console.log('getUserTimelineRequest', res);
          if (res) {
            let resData = res.data
            if (resData.length <= 0) {
              this.setState({ getUserTimelineData: { ...this.state.getUserTimelineData, refreshing: false, finished: true, offset: this.state.getUserTimelineData.offset + this.state.getUserTimelineData.limit } })
              return
            }
            this.setState({ getUserTimelineData: { ...this.state.getUserTimelineData, data: this.state.getUserTimelineData.data.concat(resData), refreshing: false, finished: false, offset: this.state.getUserTimelineData.offset + this.state.getUserTimelineData.limit } })
          }
        })

    })

  }
  refreshUserTimeline = () => {
    this.setState({ getUserTimelineData: { ...this.state.getUserTimelineData, data: [], refreshing: false, finished: false, offset: 0 }, filter: { ...this.state.filter, show: false } }, () =>
      this.getUserTimeline()
    )
  }
  render() {

    // Language Direction
    const { lang_orientation: dir } = this.props.defaultLanguage;
    // Props
    const { authData, } = this.props;

    const { getUserTimelineData } = this.state;

    return (
      <Container fluid>
        <div id='iedu-layout'>
          <NavBar />
          <div id='page-content'>
            <Header lite={true} heading={t('Tasks List')} backBtn={true} func={[this.refreshUserTimeline]} showChildSelector={true} />
            <div className='grey-section'>


              {/* FILTER BAR */}
              <div className="filter-bar class-view-filter-bar">
                {/* DATE */}
                <div className="filter filter-date" >
                  <div className="filter-trigger" onClick={() => this.state.filter.toggleFilter('date_range_picker')}>
                    <FontAwesomeIcon
                      icon={(this.state.filter.show == 'date_range_picker') ? faChevronUp : faChevronDown}
                      color={Gray}
                      className="filter-arrow"

                    />
                    <strong className="filter-title">{t('Date Range')}</strong>
                    <span className="selected-types">{moment(this.state.filter.selectedDateRange.startDate).format('DD-MM-YYYY')} -- {moment(this.state.filter.selectedDateRange.endDate).format('DD-MM-YYYY')}</span>
                  </div>
                  {(this.state.filter.show == 'date_range_picker')
                    && <div className='filter-box' style={{ display: (this.state.filter.show == 'date_range_picker') ? 'initial' : 'none' }}>
                      {/* <DateRangePicker
                        ranges={[this.state.filter.selectedDateRange]}
                        onChange={(date) => {
                          console.log('range', date, date.selection.startDate, date.selection.endDate);
                          this.state.filter.setData('selectedDateRange', { startDate: new Date(date.selection.startDate), endDate: new Date(date.selection.endDate), key: 'selection' })
                        }}
                        // className='filter-date-picker'
                        style={{ backgroundColor: '#fff' }}
                      /> */}
                    </div>
                  }
                </div>
                {/* UT TYPES */}
                <div className="filter filter-types">
                  <div className="filter-trigger" onClick={() => this.state.filter.toggleFilter('ut_types')}>
                    <FontAwesomeIcon
                      icon={(this.state.filter.show == 'ut_types') ? faChevronUp : faChevronDown}
                      color={Gray}
                      className="filter-arrow"
                    />
                    <strong className="filter-title">{t('Types')}</strong>
                    <span className="selected-types"><span>{(this.state.filter.selectedUT.length > 0) ? this.state.filter.selectedUT.length + ' Selected' : 'Select'}</span></span>
                  </div>
                  <div className="filter-box" style={{ display: (this.state.filter.show == 'ut_types') ? 'initial' : 'none' }}>
                    <ButtonGroup size="sm" className='mb-1'>
                      <Button variant="primary" onClick={() => this.state.filter.setData('selectedUT', defaultTimelineTypes.map((v, i) => { return { label: v.name, value: v.ut_type } }))}>Select All</Button>
                      <Button variant="warning" onClick={() => this.state.filter.setData('selectedUT', [])}>De-Select All</Button>
                    </ButtonGroup>
                    <Select
                      isMulti={true}
                      closeMenuOnSelect={false}
                      options={defaultTimelineTypes.map((v, i) => { return { label: v.name, value: v.ut_type } })}
                      onChange={(event) => this.state.filter.setData('selectedUT', event)}
                      value={this.state.filter.selectedUT}
                      menuPortalTarget={document.body}
                      styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                    />
                  </div>
                </div>
                {/* BTN SEARCH */}
                <div className="filter-btn d-flex align-items-center" style={{ marginLeft: 10 }}>
                  <button type="button" className="btn btn-primary" id="btn_filter_modal_apply" style={{ width: 50, height: 50, textAlign: 'center' }} onClick={() => {
                    this.setState({ getUserTimelineData: { ...this.state.getUserTimelineData, data: [], refreshing: false, finished: false, offset: 0 }, filter: { ...this.state.filter, show: false } }, () =>
                      this.getUserTimeline()
                    )
                  }}>
                    {this.state.getUserTimelineData.refreshing ? (
                      <Spinner size='sm' animation='grow' variant='light' />

                    ) : <FontAwesomeIcon
                      icon={faSearch}
                      color={White}
                      style={{ fontSize: '24px', }}
                    />}
                  </button>
                </div>
              </div>


              <Row>
                <Col md={8} className='class-view-timeline'>
                  {getUserTimelineData.data.map((ut, utIndex) => (
                    <TimelineCard
                      key={utIndex}
                      data={ut}
                      authData={this.props.authData}
                      dir={dir}
                    />
                  ))}



                  <div className="d-grid gap-2">
                    <Button variant="dark" style={{ borderRadius: 20 }} onClick={() => this.getUserTimeline()} disabled={(getUserTimelineData.finished)}>
                      {getUserTimelineData.refreshing ? (
                        <Spinner size='sm' animation='grow' variant='light' />
                      ) : (
                        <>
                          {(getUserTimelineData.finished) ? t('No more posts found!') : t('Load More')}
                        </>
                      )}
                    </Button>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  authData: state.auth.authData,
  defaultLanguage: state.language.defaultLanguage,
  selectedChild: state.selectedChild.data,
});

export default connect(mapStateToProps, null)(ClassView);
