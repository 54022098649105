import React, { Component } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { connect } from 'react-redux';
import Login from '../components/Login/_Login';
import Dashboard from '../components/Dashboard/_Dashboard';
import Profile from '../components/Profile/_Profile';
import DownloadMedia from '../components/DownloadMedia/_DownloadMedia';
import Calendar from '../components/Calendar/_Calendar';
import ExamCalendar from '../components/ExamCalendar/_ExamCalendar';
import Timetable from '../components/Timetable/_Timetable';
import Analysis from '../components/Analysis/_Analysis';
import LessonDetails from '../components/LessonDetails/_LessonDetails';
import Exams from '../components/Exams/_Exams';
import AttemptExam from '../components/Exams/_AttemptExam';
import Subject from '../components/Subject/_Subject';
import Album from '../components/DownloadMedia/Album';
import Assignments from '../components/Assignments/_Assignments';
import Assignment from '../components/Assignments/Assignment';
import ClassBlog from '../components/ClassBlog/_ClassBlog';
import ClassView from '../components/ClassView/_ClassView';
import Appointments from '../components/Appointments/_Appointments';
import ReportCardGenerator from '../components/ReportCardGenerator/_ReportCardGenerator';
import Chats from '../components/_partials/Chats/_Chats';
import CAS from '../components/CAS/_CAS';
import Essay from '../components/Essay/_Essay';
import TOK from '../components/TOK/_TOK';
import { Scrollbar } from 'react-scrollbars-custom';

/**
 * App Routes
 */
class AppRoutes extends Component {

  render() {
    const { authenticated } = this.props;

    return (
      <Scrollbar rtl={(this.props.languages.defaultLanguage.lang_orientation == 1) ? true : false} style={{ width: '100vw', height: '100vh' }} >

        {authenticated &&
          <Chats />
        }

        <Routes>
          <Route
            path='/'
            element={
              authenticated ? <Navigate to='/dashboard' /> : <Login />
            }
          />
          {authenticated ? (
            <>
              <Route path='/dashboard' element={<Dashboard />} />
              <Route path='/profile' element={<Profile />} />
              <Route path='/download-media' element={<DownloadMedia />} />
              <Route path='/download-media/album/:album_id/:album_name' element={<Album />} />
              <Route path='/calendar' element={<Calendar />} />
              <Route path='/exam-calendar' element={<ExamCalendar />} />
              <Route path='/timetable' element={<Timetable />} />
              <Route path='/analysis' element={<Analysis />} />
              <Route path='/lesson-details/:asl_id' element={<LessonDetails />} />
              <Route path='/homework' element={<Exams is_homework={true} />} />
              <Route path='/exams' element={<Exams is_homework={false} />} />
              <Route path='/exams/:acs_id/:acs_name' element={<Exams />} />
              <Route path='/attempt-exam/:exams_id/:exam_retake_id/:is_homework' element={<AttemptExam />} />
              <Route path='/cas' element={<CAS />} />
              <Route path='/essay' element={<Essay />} />
              <Route path='/tok' element={<TOK />} />


              <Route path='/subject/:acs_id/:acs_name' element={<Subject />} />
              <Route path='/projects' element={<Assignments />} />
              <Route path='/project/:assid/:assname' element={<Assignment />} />
              <Route path='/class-blog' element={<ClassBlog />} />
              <Route path='/tasks-list' element={<ClassView />} />
              <Route path='/appointments' element={<Appointments />} />
              <Route path='/report-card-generator' element={<ReportCardGenerator />} />
            </>
          ) : (
            <Route path='*' element={<Navigate to='/' />} />
          )}
        </Routes>
      </Scrollbar>
    );
  }
}

const mapStateToProps = (state) => ({
  authenticated: state.auth.authenticated,
  authData: state.auth.authData,
  languages: state.language,
});

export default connect(mapStateToProps, null)(AppRoutes);
